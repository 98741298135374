import setPhoneNumberStyle from './setPhoneNumberStyle';
import SortBy from './SortBy';

const RowFilter = (allowedList, list, users, sortby) => {
  let newRow = [];
  let username = '';
  let email = '';
  users
    ? Object.entries(list).map((row) =>
        allowedList?.includes(row[0]) && row[0] === 'email'
          ? row[1] != null
            ? (email = row[1]) && newRow.push(['email', email])
            : ''
          : allowedList.includes(row[0]) && row[0] === 'name'
          ? (username = row[1])
          : allowedList.includes(row[0]) && row[0] === 'last_name'
          ? (username = username + ' ' + row[1]) && newRow.push(['username', username])
          : allowedList.includes(row[0]) && row[0] === 'division'
          ? newRow.push(['division', row[1]?.name])
          : allowedList.includes(row[0]) && row[0] === 'phone'
          ? newRow.push(['phone', setPhoneNumberStyle(row[1])])
          : allowedList.includes(row[0])
          ? newRow.push(row)
          : ''
      )
    : '';
  return users ? SortBy(newRow, sortby) : [];
};

export default RowFilter;
