import React, { useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useDispatch } from 'react-redux';
import { exportSubscriptionList } from '../../slices/subscription';

const style = {
  width: '100%',
  maxWidth: 360,
  bgcolor: 'background.paper',
};
const ActionsListMobileVersion = ({
  openModalHandler,
  subscriptionID,
  setSnackbar,
  setLoading,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const dispatch = useDispatch();
  const addUserHnadler = () => {
    openModalHandler();
    handleClose();
  };
  const exportFileHandler = (event) => {
    dispatch(exportSubscriptionList(subscriptionID)).then((res) => {
      res.error
        ? setSnackbar({
            open: true,
            text: res.error.message,
            state: 'error',
            autoHide: false,
          })
        : setSnackbar({
            open: true,
            text: res.payload.message,
            state: 'success',
            autoHide: false,
          });
    });
    handleClose();
  };
  const uploadFile = () => {
    setLoading(true);
    document.querySelector('#file').click();
    handleClose();
  };

  const downloadExampleFile = () => {
    document.querySelector('#download-example-file').click();
    handleClose();
  };

  return (
    <div className="list-mobile-version">
      <MoreHorizIcon
        id="demo-positioned-button"
        aria-controls={open ? 'demo-positioned-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      />

      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        disableEnforceFocus
      >
        <MenuItem onClick={addUserHnadler}>Ajouter un utilisateur</MenuItem>
        <MenuItem onClick={exportFileHandler}>Exporter le tableau sous format excel</MenuItem>
        <MenuItem onClick={uploadFile}>Importer une liste à partir d'un fichier excel</MenuItem>
        <MenuItem onClick={downloadExampleFile}>Télécharger un exemple de fichier excel</MenuItem>
      </Menu>
    </div>
  );
};
export default ActionsListMobileVersion;
