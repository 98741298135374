import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import CardWrapper from '../CardWrapper/CardWrapper';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import FormControl from '../../components/Form/FormControl';
import { useDispatch } from 'react-redux';
import Button from '../Button';
import './_addPointsModal.scss';

const AddPointsModal = ({ id, open, handleClose, data, ...rest }) => {
  const dispatch = useDispatch();
  const initialValues = {
    code: '',
    amount: '',
    description: '',
  };
  const validationSchema = Yup.object({
    code: Yup.string().min(10).required('Veuillez renseigner le code'),
    amount: Yup.number().required('Veuillez renseigner le montant'),
  });

  const onSubmit = async (values, submitProps) => {
    try {
      submitProps.setStatus({ success: true });
      submitProps.setSubmitting(false);
      data.setClicked(!data.clicked);
      handleClose(id);
    } catch (err) {
      if (!err.message) {
      } else {
        submitProps.setErrors({ code: err.message });
      }

      submitProps.setStatus({ success: false });
      submitProps.setSubmitting(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={(e, reason) => {
        handleClose(id);
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="ta-modal add-points-modal"
    >
      <CardWrapper
        title="Ajouter des points"
        className="add-points-modal-title"
        closeBtn
        onClick={() => handleClose(id)}
      >
        <DialogContent className="ta-modal-content-scroll add-points-form">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {(formik) => {
              return (
                <Form>
                  <FormControl
                    control="input"
                    type="string"
                    name="code"
                    placeholder="Entrez votre code"
                    className={`${
                      formik.errors.code && formik.touched.code
                        ? 'form-control error-input'
                        : 'form-control'
                    }`}
                  />
                  <FormControl
                    control="input"
                    type="number"
                    name="amount"
                    placeholder="Montant en dinar"
                    className={`${
                      formik.errors.amount && formik.touched.amount
                        ? 'form-control error-input'
                        : 'form-control'
                    }`}
                  />
                  <FormControl
                    control="input"
                    type="string"
                    name="description"
                    placeholder="Description"
                  />
                  <div className="modal-actions">
                    <Button
                      type="submit"
                      disabled={!formik.isValid || formik.isSubmitting}
                      text="Ajouter des points"
                      className={
                        !formik.isValid || formik.isSubmitting ? 'disabled-button' : 'blue'
                      }
                    >
                      Ajouter des points
                    </Button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </DialogContent>
      </CardWrapper>
    </Dialog>
  );
};

export default AddPointsModal;
