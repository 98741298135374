import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import './_ProgressItem.scss';

const ProgressItem = ({ subject, progression }) => {
  return (
    <div className="progress-item">
      <div className="subject-info">
        <div className="subject">
          <img src={subject?.thumbnail} alt="subject-img" />
          <div className="subject-name">{subject?.name}</div>
        </div>

        <div className="progression">
          {progression ? Math.round((progression?.nbCompleted / subject.subjectVideos) * 100) : 0}%
        </div>
      </div>
      <Box sx={{ flexGrow: 1 }}>
        <LinearProgress
          variant="determinate"
          value={
            progression ? Math.round((progression?.nbCompleted / subject.subjectVideos) * 100) : 0
          }
        />
      </Box>
    </div>
  );
};

export default ProgressItem;
