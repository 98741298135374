import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../utilities/axios';

const initialState = {
  user: [],
  status: 'idle',
  error: null,
};

export const fetchUser = createAsyncThunk('user/fetchUser', async () => {
  let data;
  try {
    const response = await axios.get(`/me`);

    data = await response.data;
    if (response.status === 200) {
      return data.payload;
    }

    throw new Error(response.statusText);
  } catch (err) {
    if (err?.code === 401) {
      // localStorage.removeItem('token');
      // delete axios.defaults.headers.common.Authorization;
      window.location.reload();
    }
    return Promise.reject(err.message ? err.message : data?.message);
  }
});

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchUser.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchUser.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.user = action.payload;
    },
    [fetchUser.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },
  },
});

export const reducer = slice.reducer;

export default slice;
