import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import CardWrapper from '../../CardWrapper/CardWrapper';
import { useSelector } from 'react-redux';
import Button from '../../Button';
import './_index.scss';
import HistoryCard from './HistoryCard';
import TableComponent from '../../Table';
import constants from '../../../utilities/constants';
import getImportFileState from '../../../utilities/getImportFileState';

const header = constants.ImportFileHeaderTable;
const allowedList = constants.ImportFileHistoryAllowedList;
const sortBy = constants.ImportFileHistorySortBy;

const ImportFileHistoryModal = ({ id, open, handleClose, data, ...rest }) => {
  const { usersList, totalAddedUsers, totalRejectedUsers } = useSelector(
    (state) => state.subscription
  );
  const [openUsersDetails, setOpenUsersDetails] = useState({ succeeded: false, failed: false });
  const [tableList, setTableList] = useState({
    newUser: false,
    existingUser: false,
    notEnoughSeats: false,
    wrongAffiliation: false,
  });
  const closeModalHandler = () => {
    handleClose(id);
    setOpenUsersDetails({ succeeded: false, failed: false });
  };

  const goBackHandler = () => {
    !tableList.existingUser &&
    !tableList.newUser &&
    !tableList.notEnoughSeats &&
    !tableList.wrongAffiliation
      ? setOpenUsersDetails({ succeeded: false, failed: false })
      : setTableList({
          newUser: false,
          existingUser: false,
          notEnoughSeats: false,
          wrongAffiliation: false,
        });
  };
  return (
    <Dialog
      open={open}
      onClose={(e, reason) => {
        handleClose(id);
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="ta-modal import-file-history-modal"
    >
      <CardWrapper
        title="Historique des derniers ajouts des utilisateurs"
        className="import-file-history-modal-title"
        closeBtn
        goBack={openUsersDetails.succeeded || openUsersDetails.failed ? true : false}
        goBackHandler={goBackHandler}
        onClick={() => handleClose(id)}
      >
        <DialogContent className="ta-modal-content-scroll import-file-history-modal-content">
          {!openUsersDetails.succeeded && !openUsersDetails.failed ? (
            <div className="import-file-history-cards">
              <HistoryCard
                title="Succès"
                success
                openUsersDetails={openUsersDetails}
                setOpenUsersDetails={setOpenUsersDetails}
                usersNumber={totalAddedUsers}
                totalUsersNumber={totalAddedUsers + totalRejectedUsers}
                text={
                  totalAddedUsers < 2
                    ? ' utilisateur est ajouté avec succès.'
                    : ' utilisateurs sont ajoutés avec succès.'
                }
              />
              <HistoryCard
                title="Erreur"
                openUsersDetails={openUsersDetails}
                setOpenUsersDetails={setOpenUsersDetails}
                usersNumber={totalRejectedUsers}
                totalUsersNumber={totalAddedUsers + totalRejectedUsers}
                text={
                  totalRejectedUsers < 2
                    ? ' utilisateur est rejeté.'
                    : ' utilisateurs sont rejetés.'
                }
              />
            </div>
          ) : !tableList.existingUser &&
            !tableList.newUser &&
            !tableList.notEnoughSeats &&
            !tableList.wrongAffiliation ? (
            <div className="import-file-history-cards-details">
              {openUsersDetails.succeeded ? (
                <>
                  {usersList?.existedUserCreated?.length !== 0 && (
                    <HistoryCard
                      title="Utilisateurs déja inscrits"
                      success
                      existingUser
                      setTableList={setTableList}
                      usersNumber={usersList?.existedUserCreated?.length}
                      totalUsersNumber={totalAddedUsers}
                      text={
                        usersList?.existedUserCreated?.length < 2
                          ? ' utilisateur déja inscrit est ajouté avec succès.'
                          : ' utilisateurs déja inscrits sont ajoutés avec succès.'
                      }
                    />
                  )}
                  {usersList?.newUserCreated?.length !== 0 && (
                    <HistoryCard
                      setTableList={setTableList}
                      title="Nouveaux utilisateurs"
                      success
                      newUser
                      usersNumber={usersList?.newUserCreated?.length}
                      totalUsersNumber={totalRejectedUsers}
                      text={
                        usersList?.newUserCreated?.length < 2
                          ? ' nouveau utilisateur est ajouté avec succès.'
                          : ' nouveaux utilisateurs sont ajoutés avec succès.'
                      }
                    />
                  )}
                </>
              ) : (
                <>
                  {usersList?.noSeatUser?.length !== 0 && (
                    <HistoryCard
                      setTableList={setTableList}
                      title="Manque de place"
                      notEnoughSeats
                      usersNumber={usersList?.noSeatUser?.length}
                      totalUsersNumber={totalRejectedUsers}
                      text={
                        usersList?.noSeatUser?.length < 2
                          ? " utilisateurs n'est pas ajouté à cause du manque de place."
                          : ' utilisateurs ne sont pas ajoutés à cause du manque de place.'
                      }
                      className="no-seats-card"
                    />
                  )}
                  {usersList?.userWithDifferentDivision?.length !== 0 && (
                    <HistoryCard
                      setTableList={setTableList}
                      title="Classes non compatibles avec l'offre"
                      wrongAffiliation
                      usersNumber={usersList?.userWithDifferentDivision?.length}
                      totalUsersNumber={totalRejectedUsers}
                      text={
                        usersList?.userWithDifferentDivision?.length < 2
                          ? " utilisateur n'est pas ajouté à cause de l'incompatibilité de son classe avec l'offre sélectionné."
                          : " utilisateurs ne sont pas ajoutés à cause de l'incompatibilité de leurs classes avec l'offre sélectionné."
                      }
                    />
                  )}
                </>
              )}
            </div>
          ) : (
            <div className="table-list">
              <TableComponent
                header={header}
                rows={
                  tableList.newUser
                    ? usersList?.newUserCreated
                    : tableList.existingUser
                    ? usersList?.existedUserCreated
                    : tableList.notEnoughSeats
                    ? usersList?.noSeatUser
                    : usersList?.userWithDifferentDivision
                }
                isImportListState={getImportFileState(tableList.newUser || tableList.existingUser)}
                isPaging={true}
                importFile
                allowedList={allowedList}
                sortBy={sortBy}
                loading={data?.loading}
                setLoading={data?.setLoading}
              />
            </div>
          )}

          <div className="import-file-history-action">
            <Button onClick={closeModalHandler} text="Fermer" className="white" />
          </div>
        </DialogContent>
      </CardWrapper>
    </Dialog>
  );
};

export default ImportFileHistoryModal;
