import React, { useState, useEffect } from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import CheckIcon from '@mui/icons-material/Check';
import RowFilter from '../../utilities/RowFilter';
import mailIcon from '../../assets/img/Mail.png';
import listIcon from '../../assets/img/Bullets.png';
import { useDispatch, useSelector } from 'react-redux';
import { openModal } from '../../slices/modals';
import changeDateFormat from '../../utilities/changeDateFormat';
import { sendSMSPassword, updateAffiliation } from '../../slices/subscription';
import { fetchStudentSubjects, getStudentSubjectProgress } from '../../slices/student';
import Loader from '../Loader';
import capitalizeString from '../../utilities/capitalizeString';

const Row = (props) => {
  const dispatch = useDispatch();
  const {
    row,
    ifVoid,
    isAction,
    isStatus,
    allowedList,
    sortBy,
    users,
    id,
    snackbar,
    setSnackbar,
    importFile,
    isImportListState,
    loading,
    setLoading,
  } = props;
  const { subscription, getSubscriptionStatus } = useSelector((state) => state.subscription);
  let filteredRow = importFile
    ? RowFilter(allowedList, row, users, sortBy)
    : users
    ? RowFilter(allowedList, row.user, users, sortBy)
    : row;
  const [clickedId, setClickedId] = useState();
  useEffect(() => {
    if (getSubscriptionStatus === 'succeeded') {
      setClickedId(undefined);
      setLoading(false);
    }
  }, [getSubscriptionStatus]);

  const updateAffiliationHandler = () => {
    setClickedId(id);
    setLoading(true);

    dispatch(
      updateAffiliation({
        subscriptionID: subscription.id,
        affiliationID: row?.affiliation?.id,
        user: {
          name: capitalizeString(row?.user?.name) + ' ' + capitalizeString(row?.user?.last_name),
          state: row?.affiliation?.active ? 'désactivé' : 'activé',
        },
      })
    ).then((res) => {
      res.error
        ? setSnackbar({
            open: true,
            text: res.error.message,
            state: 'error',
            autoHide: true,
          })
        : setSnackbar({
            open: true,
            text: res.payload.message,
            state: 'success',
            autoHide: true,
          });
    });
  };

  const sendSMSPasswordHandler = () => {
    dispatch(
      sendSMSPassword({
        subscriptionID: subscription.id,
        userID: filteredRow[0][1],
      })
    ).then((res) => {
      res.error
        ? setSnackbar({
            open: true,
            text: res.error.message,
            state: 'error',
            autoHide: true,
          })
        : setSnackbar({
            open: true,
            text: res.payload.message,
            state: 'success',
            autoHide: true,
          });
    });
  };
  const progressModalHandler = (id, name) => {
    dispatch(fetchStudentSubjects(id));
    dispatch(getStudentSubjectProgress(id));
    dispatch(openModal('modal-progression', { name }));
  };
  return (
    <React.Fragment>
      <TableRow
        className={isStatus ? 'left-tbody-rows row' : 'row'}
        sx={{ '& > *': { border: '1px solid #EBEBF3', borderRadius: '15px', height: '20px' } }}
      >
        {filteredRow.map((el, key) => (
          <React.Fragment key={key}>
            <TableCell className="row-content">
              {el === '' ? (
                ifVoid
              ) : el?.[0] === 'date' ? (
                changeDateFormat(el, true)
              ) : el?.[0] === 'username' ? (
                el?.[1]
              ) : el?.[0] === 'division' ? (
                <span className="class-info">
                  <span className="class-name">{el[1]}</span>
                </span>
              ) : (
                el?.[1]
              )}
            </TableCell>
            {isAction && key === 3 && (
              <TableCell>
                <div className="table-actions">
                  <img
                    title="envoyer le mot de passe"
                    src={mailIcon}
                    alt="mail-icon"
                    onClick={sendSMSPasswordHandler}
                  />
                  <button
                    title="Progression"
                    className="progress-modal-btn"
                    onClick={() => progressModalHandler(filteredRow[0][1], filteredRow[1][1])}
                  >
                    <img src={listIcon} alt="list-icon" />
                  </button>

                  <button
                    title={
                      row?.affiliation?.active === true
                        ? 'Désactiver cet utilisateur'
                        : 'Activer cet utilisateur'
                    }
                    className={
                      clickedId === id && loading
                        ? 'loading-disable-user-btn'
                        : row?.affiliation?.active === true
                        ? 'disable-subscriber-btn'
                        : 'enable-subscriber-btn'
                    }
                    onClick={updateAffiliationHandler}
                  >
                    {clickedId === id && loading ? (
                      <Loader />
                    ) : row?.affiliation?.active === true ? (
                      'Désactiver'
                    ) : (
                      'Activer'
                    )}
                  </button>
                </div>
              </TableCell>
            )}
          </React.Fragment>
        ))}
        {isStatus && (
          <TableCell>
            <CheckIcon className="row-actions" />
          </TableCell>
        )}
        {isImportListState && (
          <TableCell className="import-file-state">
            <div className={isImportListState.className}>{isImportListState.state}</div>
          </TableCell>
        )}
      </TableRow>
    </React.Fragment>
  );
};

export default Row;
