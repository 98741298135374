import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../utilities/axios';
import changeDateFormat from '../utilities/changeDateFormat';
import { serialize } from 'object-to-formdata';

const initialState = {
  subscription: {},
  seats: [],

  exportStatus: 'idle',
  exportSuccess: '',
  exportError: '',

  importStatus: 'idle',
  importSuccess: '',
  importError: '',
  usersList: [],
  totalAddedUsers: 0,
  totalRejectedUsers: 0,

  smsStatus: 'idle',
  smsSuccess: '',
  smsError: '',

  getSubscriptionStatus: 'idle',
  getSubscriptionSuccess: '',
  getSubscriptionError: '',

  affiliationStatus: 'idle',
  affiliationSuccess: '',
  affiliationError: '',

  existingUserStatus: 'idle',
  existingUserSuccess: '',
  existingUserError: '',

  nonExistingUserStatus: 'idle',
  nonExistingUserSuccess: '',
  nonExistingUserError: '',
};

export const getBusinessSubscription = createAsyncThunk(
  'subscription/getBusinessSubscription',
  async (id) => {
    let data;
    try {
      const response = await axios.get(`/subscriptions/${id}`);
      data = await response.data;
      if ((response.status = 200)) {
        return data.payload;
      }

      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const updateAffiliation = createAsyncThunk(
  'subscription/updateAffiliation',
  async (dataToSend, thunkAPI) => {
    let data;
    const { subscriptionID, affiliationID, user } = dataToSend;
    try {
      const response = await axios.post(`/subscriptions/${subscriptionID}/seats/${affiliationID}
    `);
      data = await response.data;
      if ((response.status = 200)) {
        thunkAPI.dispatch(getBusinessSubscription(subscriptionID));
        return {
          message: `  ${user.name}
              est
           ${user.state}
            avec succès!`,
        };
      }

      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject("Une erreur s'est produite");
    }
  }
);
export const sendSMSPassword = createAsyncThunk('subscription/sendSMSPassword', async (ids) => {
  let data;
  const { subscriptionID, userID } = ids;
  try {
    const response = await axios.post(`/subscriptions/${subscriptionID}/seats/${userID}/sms`);

    data = await response.data;
    if ((response.status = 200)) {
      return { message: 'Le message est envoyé avec succès' };
    }

    throw new Error(response.statusText);
  } catch (err) {
    return Promise.reject(
      err.code === 404
        ? 'Aucune affiliation trouvée'
        : "Une Erreur S'est Produite, Veuillez Réessayer Plus Tard"
    );
  }
});

export const exportSubscriptionList = createAsyncThunk(
  'subscription/exportSubscriptionList',

  async (subscriptionID) => {
    try {
      const config = { responseType: 'blob' };
      const response = await axios.get(`subscriptions/${subscriptionID}/export`, config);

      let fileName = `export-data-${changeDateFormat(new Date(), true)}.csv`;
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        // IE variant
        window.navigator.msSaveOrOpenBlob(
          new Blob([response.data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          }),
          fileName
        );
      } else {
        const url = window.URL.createObjectURL(
          new Blob([response.data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          })
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
      }

      if ((response.status = 200)) {
        return { message: 'Le tableau est exporté avec Succès!' };
      }
    } catch (err) {
      return Promise.reject('Aucun abonnement trouvé');
    }
  }
);

export const importSubscriptionList = createAsyncThunk(
  'subscription/importSubscriptionList',
  async (dataToSend, thunkAPI) => {
    try {
      const { subscriptionID, fileData } = dataToSend;
      if (!(fileData.file == null)) {
        let file = serialize(fileData, { indices: true });
        const response = await axios.post(`/subscriptions/${subscriptionID}/import`, file);
        let data = await response.data;
        if ((response.status = 200)) {
          thunkAPI.dispatch(getBusinessSubscription(subscriptionID));

          return Object.entries(response?.data?.payload).find(
            (usersList) =>
              (usersList[0] === 'noSeatUser' || usersList[0] === 'userWithDifferentDivision') &&
              usersList[1].length !== 0
          )
            ? {
                data: response?.data?.payload,
                message:
                  "Une erreur s'est produite lors de l'import de la liste, certains utilisateurs ne sont pas ajoutés...",
              }
            : { message: 'Le tableau est importé avec Succès!' };
        }
      }
    } catch (err) {
      return Promise.reject('Invalide type de fichier ou la format de données est erronée');
    }
  }
);

export const addExistingUser = createAsyncThunk(
  'subscription/addExistingUser',
  async (informations, thunkAPI) => {
    const { subscriptionID, studentID, division, subscription } = informations;
    let data;
    try {
      const response = await axios.post(`/subscriptions/${subscriptionID}/exist/seats`, {
        studentID,
      });
      data = await response.data;
      if ((response.status = 200)) {
        thunkAPI.dispatch(getBusinessSubscription(subscriptionID));
        return "L'utilisateur est ajouté avec succès!";
      }

      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject({
        message: `La classe ${division} n'existe pas dans l'offre ${subscription}`,
      });
    }
  }
);

export const addNonExistingUser = createAsyncThunk(
  'subscription/addNonExistingUser',
  async (formData, thunkAPI) => {
    const { subscriptionID, name, lastName, phone, division } = formData;
    let data;
    try {
      const response = await axios.post(`/subscriptions/${subscriptionID}/seats`, {
        name,
        lastName,
        phone,
        division,
      });

      data = await response.data;
      if ((response.status = 200)) {
        thunkAPI.dispatch(getBusinessSubscription(subscriptionID));
        return "L'utilisateur est ajouté avec succès!";
      }

      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(
        err?.errors?.phone[0]?.includes('not valid')
          ? 'Le numéro de téléphone est incorrect!'
          : err?.errors?.phone[0]?.includes('already used')
          ? 'Le numéro de téléphone appartient à autre compte!'
          : 'Une erreur est survenue. Veuillez essayer à nouveau dans quelques minutes.'
      );
    }
  }
);

const slice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {},
  extraReducers: {
    [getBusinessSubscription.pending]: (state) => {
      state.getSubscriptionStatus = 'loading';
    },
    [getBusinessSubscription.fulfilled]: (state, action) => {
      state.getSubscriptionStatus = 'succeeded';
      state.subscription = action.payload.businessSubscription;
      state.seats = action.payload.seats;
    },
    [getBusinessSubscription.rejected]: (state, action) => {
      state.getSubscriptionStatus = 'failed';
      state.getSubscriptionError = action.error.message;
    },

    [sendSMSPassword.pending]: (state) => {
      state.smsStatus = 'loading';
    },
    [sendSMSPassword.fulfilled]: (state, action) => {
      state.smsStatus = 'succeeded';
      state.smsSuccess = action.payload.message;
    },
    [sendSMSPassword.rejected]: (state, action) => {
      state.smsStatus = 'failed';
      state.smsError = action.error.message;
    },

    [exportSubscriptionList.pending]: (state) => {
      state.exportStatus = 'loading';
    },
    [exportSubscriptionList.fulfilled]: (state, action) => {
      state.exportStatus = 'succeeded';
      state.exportSuccess = action.payload;
    },
    [exportSubscriptionList.rejected]: (state, action) => {
      state.exportStatus = 'failed';
      state.exportError = action.payload;
    },
    [importSubscriptionList.pending]: (state) => {
      state.importStatus = 'loading';
    },
    [importSubscriptionList.fulfilled]: (state, action) => {
      state.importStatus = 'succeeded';
      state.importSuccess = action.payload;
      state.usersList = action.payload?.data;
      state.totalAddedUsers =
        action.payload?.data?.existedUserCreated?.length +
        action.payload?.data?.newUserCreated?.length;
      state.totalRejectedUsers =
        action.payload?.data?.noSeatUser?.length +
        action.payload?.data?.userWithDifferentDivision?.length;
    },
    [importSubscriptionList.rejected]: (state, action) => {
      state.importStatus = 'failed';
      state.importError = action.error.message;
    },
    [updateAffiliation.pending]: (state) => {
      state.affiliationStatus = 'loading';
    },
    [updateAffiliation.fulfilled]: (state, action) => {
      state.affiliationStatus = 'succeeded';
      state.affiliationSuccess = action.payload.message;
    },
    [updateAffiliation.rejected]: (state, action) => {
      state.affiliationStatus = 'failed';
      state.affiliationError = action.error.message;
    },

    [addExistingUser.pending]: (state) => {
      state.existingUserStatus = 'loading';
    },
    [addExistingUser.fulfilled]: (state, action) => {
      state.existingUserStatus = 'succeeded';
      state.existingUserSuccess = action.payload;
    },
    [addExistingUser.rejected]: (state, action) => {
      state.existingUserStatus = 'failed';
      state.existingUserError = action.message;
    },

    [addNonExistingUser.pending]: (state) => {
      state.nonExistingUserStatus = 'loading';
    },
    [addNonExistingUser.fulfilled]: (state, action) => {
      state.nonExistingUserStatus = 'succeeded';
      state.nonExistingUserSuccess = action.payload;
    },
    [addNonExistingUser.rejected]: (state, action) => {
      state.nonExistingUserStatus = 'failed';
      state.nonExistingUserError = action.error.message;
    },
  },
});
export const reducer = slice.reducer;

export default slice;
