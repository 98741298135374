import React from 'react';
import './_index.scss';

const Search = ({ setValue, value }) => {
  const handleChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <div className="search">
      <input value={value} type="search" placeholder="Recherche..." onChange={handleChange} />
      <div value={value} type="search"></div>
    </div>
  );
};
export default Search;
