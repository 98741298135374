import React from 'react';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import './_historyCard.scss';
import Button from '../../Button';
const HistoryCard = ({
  success,
  openUsersDetails,
  setOpenUsersDetails,
  setTableList,
  newUser,
  existingUser,
  notEnoughSeats,
  usersNumber,
  totalUsersNumber,
  text,
  className,
  title,
}) => {
  const seeMoreHandler = () => {
    success
      ? setOpenUsersDetails({ succeeded: true, failed: false })
      : setOpenUsersDetails({ succeeded: false, failed: true });
  };

  const seeTableHandler = () => {
    success && newUser
      ? setTableList({
          newUser: true,
          existingUser: false,
          notEnoughSeats: false,
          wrongAffiliation: false,
        })
      : success && existingUser
      ? setTableList({
          newUser: false,
          existingUser: true,
          notEnoughSeats: false,
          wrongAffiliation: false,
        })
      : !success && notEnoughSeats
      ? setTableList({
          newUser: false,
          existingUser: false,
          notEnoughSeats: true,
          wrongAffiliation: false,
        })
      : setTableList({
          newUser: false,
          existingUser: false,
          notEnoughSeats: false,
          wrongAffiliation: true,
        });
  };
  return (
    <div
      className={
        success ? `${className} history-card success-card` : `${className} history-card error-card`
      }
    >
      <div className="history-card-icon">{success ? <DoneIcon /> : <CloseIcon />}</div>
      <div className="history-card-status">{title}</div>
      <div className="history-card-description">
        <span className="users-number">{usersNumber}</span>/{totalUsersNumber} {text}
      </div>
      <div className="history-card-btn">
        <Button
          text="Voir détails"
          onClick={
            openUsersDetails?.succeeded === false && openUsersDetails?.failed === false
              ? seeMoreHandler
              : seeTableHandler
          }
          className={usersNumber === 0 ? 'hidden-btn' : ''}
        />

        {/* <ArrowForwardIosIcon /> */}
      </div>
    </div>
  );
};

export default HistoryCard;
