import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { TablePagination } from '@mui/material';
import Search from '../../utilities/Search';
import Row from './Row';
import './_index.scss';

const TableComponent = ({
  header,
  rows,
  ifVoid,
  isAction,
  isStatus,
  isPaging,
  allowedList,
  value,
  sortBy,
  users,
  snackbar,
  setSnackbar,
  importFile,
  isImportListState,
  loading,
  setLoading,
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const Rows = Search(rows, value);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  return (
    <div className="table-component" sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer className="table-container" sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table" className="table">
          <TableHead>
            <TableRow className={isStatus ? 'left-thead-row table-row' : 'table-row'}>
              {header.map((el, key) => (
                <TableCell key={key}>{el?.thead}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {Rows?.length == 0 ? (
              <span className="no-Data">Aucun abonné ...</span>
            ) : (
              (rowsPerPage > 0
                ? Rows?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                : Rows
              )?.map((row, key) => (
                <Row
                  key={key}
                  id={key}
                  row={row}
                  users
                  ifVoid={ifVoid}
                  isAction={isAction}
                  isStatus={isStatus}
                  allowedList={allowedList}
                  sortBy={sortBy}
                  snackbar={snackbar}
                  setSnackbar={setSnackbar}
                  importFile={importFile}
                  isImportListState={isImportListState}
                  loading={loading}
                  setLoading={setLoading}
                />
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {isPaging && (
        <TablePagination
          rowsPerPageOptions={[5, 10, 20, 50, 100]}
          component="div"
          count={Rows?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={<span>Lignes par page:</span>}
          labelDisplayedRows={({ from, to, count }) => {
            return '' + from + '-' + to + ' sur ' + count;
          }}
          SelectProps={{
            inputProps: {
              'aria-label': 'page number',
            },
          }}
        />
      )}
    </div>
  );
};

export default TableComponent;
