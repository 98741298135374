import levenshteinDistance from './levenshteinDistance';

const Search = (table, search) => {
  const Rows = table?.filter((row) => {
    if (search == null) {
      return row;
    } else if (
      row.user.id.toString().includes(search) ||
      levenshteinDistance(search, row.user.id.toString()) < 4 ||
      row.user.name.trim().toLowerCase().includes(search) ||
      levenshteinDistance(search, row.user.name.trim().toLowerCase()) < 4 ||
      row.user.last_name.trim().toLowerCase().includes(search) ||
      levenshteinDistance(search, row.user.last_name.trim().toLowerCase()) < 4 ||
      row.user.phone?.toString().trim().toLowerCase().includes(search) ||
      levenshteinDistance(search, row.user.phone?.toString().trim().toLowerCase()) < 4 ||
      row.division.name.trim().toLowerCase().includes(search) ||
      levenshteinDistance(search, row.division.name.trim().toLowerCase()) < 4
    ) {
      return row;
    }
  });
  return Rows;
};

export default Search;
