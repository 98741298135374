import React from 'react';
import './_index.scss';

const SVGLoader = () => {
  return (
    <div className="svg-loader">
      <div className="loader" />
      <span>en cours...</span>
    </div>
  );
};

export default SVGLoader;
