import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Search from '../Search/Search';
import CloseIcon from '@mui/icons-material/Close';
import { openModal } from '../../slices/modals';
import { useDispatch, useSelector } from 'react-redux';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import CSVFormat from './CSVFormat';
import './_index.scss';
import ActionsListMobileVersion from './ActionsListMobileVersion';
import { compareAsc } from 'date-fns';

const CardWrapper = ({
  title,
  children,
  className,
  addUserBtn,
  search,
  closeBtn,
  onClick,
  setValue,
  options,
  value,
  exportAsCSV,
  snackbar,
  setSnackbar,
  goBack,
  goBackHandler,
  loading,
  setLoading,
}) => {
  const dispatch = useDispatch();
  const { subscription } = useSelector((state) => state.subscription);
  const openModalHandler = () => {
    compareAsc(new Date(subscription?.end_date), new Date()) === -1
      ? setSnackbar({
          open: true,
          text: `L'offre ${subscription?.group?.name} est expirée !`,
          state: 'error',
          autoHide: true,
        })
      : dispatch(
          openModal('modal-add-user', {
            options,
            snackbar,
            setSnackbar,
            loading,
            setLoading,
          })
        );
  };

  return (
    <Card className={`card-wrapper ${className}`}>
      <CardContent>
        <div className="card-header">
          {title && (
            <div className="card-title">
              {goBack && <ChevronLeftIcon onClick={goBackHandler} />}
              <Typography gutterBottom variant="h5" component="div">
                {title}
              </Typography>
            </div>
          )}

          {(search || addUserBtn) && (
            <>
              <div className="card-actions">
                {search && <Search setValue={setValue} value={value} />}
                {addUserBtn && (
                  <button onClick={openModalHandler}>
                    <span>Ajouter un utilisateur</span>
                    {/* <PersonAddAltOutlinedIcon /> */}
                  </button>
                )}

                {exportAsCSV && (
                  <CSVFormat
                    subscriptionID={subscription.id}
                    snackbar={snackbar}
                    setSnackbar={setSnackbar}
                    loading={loading}
                    setLoading={setLoading}
                  />
                )}
                <ActionsListMobileVersion
                  openModalHandler={openModalHandler}
                  subscriptionID={subscription.id}
                  snackbar={snackbar}
                  setSnackbar={setSnackbar}
                  loading={loading}
                  setLoading={setLoading}
                  options={options}
                />
              </div>
            </>
          )}

          {closeBtn && <CloseIcon className="close-btn" onClick={onClick} />}
        </div>

        {children}
      </CardContent>
    </Card>
  );
};
export default CardWrapper;
