import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import Axios from '../utilities/axios';
const businessToken = window.localStorage.getItem('token');

const initialState = {
  subjects: [],
  allSubjects: [],
  student: {},

  subjectStatus: 'idle',
  subjectSuccess: '',
  subjectError: '',

  progressStatus: 'idle',
  progressSuccess: '',
  progressError: '',

  studentStatus: 'idle',
  studentSuccess: '',
  studentError: '',
};

export const getStudent = createAsyncThunk('student/getStudent', async (userID) => {
  let data;
  try {
    const response = await Axios.get(`/users/${userID}`);

    data = await response.data;
    if ((response.status = 200)) {
      return data.payload;
    }

    throw new Error(response.statusText);
  } catch (err) {
    return Promise.reject(err.message ? err.message : data?.message);
  }
});

export const getStudentSubjectProgress = createAsyncThunk(
  'student/getStudentSubjectProgress',

  async (studentId) => {
    let data;
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL_PROGRESS}/${studentId}/subjects`,
        {
          headers: {
            'x-api-key': process.env.REACT_APP_API_KEY,
            'Authorization': `Bearer ${businessToken}`,
          },
        }
      );
      data = await response.data;
      if ((response.status = 200)) {
        return data.data;
      }

      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const fetchStudentSubjects = createAsyncThunk(
  'student/fetchUserSubjects',
  async (userID) => {
    let data;
    try {
      const response = await Axios.get(`/users/${userID}/subjects`);

      data = await response.data;
      if ((response.status = 200)) {
        return data.payload;
      }

      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

const slice = createSlice({
  name: 'student',
  initialState,
  reducers: {},
  extraReducers: {
    [getStudentSubjectProgress.pending]: (state) => {
      state.progressStatus = 'loading';
    },
    [getStudentSubjectProgress.fulfilled]: (state, action) => {
      state.progressStatus = 'succeeded';
      state.subjects = action.payload;
    },
    [getStudentSubjectProgress.rejected]: (state, action) => {
      state.progressStatus = 'failed';
      state.progressError = action.error.message;
    },

    [fetchStudentSubjects.pending]: (state) => {
      state.subjectStatus = 'loading';
    },
    [fetchStudentSubjects.fulfilled]: (state, action) => {
      state.subjectStatus = 'succeeded';
      state.allSubjects = action.payload;
      state.subjectError = '';
    },
    [fetchStudentSubjects.rejected]: (state, action) => {
      state.subjectStatus = 'failed';
      state.subjectError = action.error.message;
    },

    [getStudent.pending]: (state) => {
      state.studentStatus = 'loading';
    },
    [getStudent.fulfilled]: (state, action) => {
      state.studentStatus = 'succeeded';
      state.student = action.payload;
    },
    [getStudent.rejected]: (state, action) => {
      state.studentStatus = 'failed';
      state.studentError = action.error.message;
    },
  },
});

export const reducer = slice.reducer;

export default slice;
