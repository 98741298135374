import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import CardWrapper from '../../CardWrapper/CardWrapper';
import Button from './../../Button';
import './_ProgressionModal.scss';
import { Divider } from '@mui/material';
import ProgressItem from './components/ProgressItem';
import { useSelector } from 'react-redux';
import LoadingScreen from '../../LoadingScreen';

const ProgressionModal = ({ id, open, handleClose, data, ...rest }) => {
  const { allSubjects, subjects, subjectStatus, subjectError } = useSelector(
    (state) => state.student
  );
  return (
    <Dialog
      open={open}
      onClose={(e, reason) => {
        handleClose(id);
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="ta-modal"
    >
      <CardWrapper
        title="Progression"
        className="progression-modal"
        closeBtn
        onClick={() => handleClose(id)}
      >
        <>
          <div className="user-name">{data?.name}</div>
          {subjectStatus === 'succeeded' ||
          (subjectStatus === 'failed' && subjectError.includes('No subjects')) ? (
            <DialogContent className="ta-modal-content-scroll">
              <div className="modal-content">
                <div className="subjects">
                  {subjectError.includes('No subjects') ? (
                    <span>Aucune matière à afficher</span>
                  ) : (
                    allSubjects?.map((subject, key) => {
                      const progression = subjects.find((elem) => elem.subject_id === subject.id);
                      return (
                        <div key={key}>
                          <ProgressItem subject={subject} progression={progression} />
                          <Divider />
                        </div>
                      );
                    })
                  )}
                </div>
              </div>
            </DialogContent>
          ) : (
            <LoadingScreen />
          )}
        </>
        <div className="modal-actions">
          <Button onClick={() => handleClose(id)} className="white" text="Fermer" />
        </div>
      </CardWrapper>
    </Dialog>
  );
};

export default ProgressionModal;
