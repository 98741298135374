import React, { useEffect, useState } from 'react';
import Button from '../../Button';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import FormControl from '../../Form/FormControl';
import { useSelector, useDispatch } from 'react-redux';
import './_addExistingUserForm.scss';
import { addExistingUser } from '../../../slices/subscription';
import Loader from '../../Loader';

const AddExistingUserForm = ({ id, open, handleClose, data, ...rest }) => {
  const dispatch = useDispatch();
  const { subscription, existingUserStatus } = useSelector((state) => state.subscription);
  const { student } = useSelector((state) => state.student);
  let [clicked, setClicked] = useState(false);
  const initialValues = {
    studentID: '',
  };
  const validationSchema = Yup.object({
    studentID: Yup.string().required("Veuillez renseigner l'identifiant"),
  });

  const onSubmit = async (values, submitProps) => {
    try {
      data?.setLoading(true);
      if (subscription?.total_seats > 0 && clicked === false) {
        if (clicked === false) {
          dispatch(
            await addExistingUser({
              subscriptionID: subscription.id,
              studentID: values.studentID,
              division: student?.division?.name,
              subscription: subscription.group?.name,
            })
          ).then((res) => {
            if (res.error) {
              submitProps.setErrors({
                studentID:
                  values.studentID !== student.id
                    ? "L'identifiant est invalide"
                    : res.error.message,
              });
              submitProps.setStatus({ success: false });
              submitProps.setSubmitting(false);
            } else {
              setClicked(true);
              handleClose(id);
              submitProps.setStatus({ success: true });
              submitProps.setSubmitting(false);

              data?.setSnackbar({
                open: true,
                text: res.payload,
                state: 'success',
                autoHide: true,
              });
            }
          });
        }
      } else {
        handleClose(id);
        data?.setSnackbar({
          open: true,
          text: 'Vous avez utilisé la totalité de vos abonnements!',
          state: 'error',
          autoHide: true,
        });
      }
    } catch (err) {
      if (!err.message) {
        submitProps.setErrors({
          studentID: "Oups, quelque chose s'est mal passé, veuillez réessayer!",
        });
      } else {
        submitProps.setErrors({ studentID: err.message });
      }

      submitProps.setStatus({ success: false });
      submitProps.setSubmitting(false);
    }
  };
  return (
    <div className="add-existing-user-form">
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        {(formik) => {
          return (
            <Form>
              <FormControl
                control="input"
                type="number"
                name="studentID"
                placeholder="L'identifiant"
                className={`${
                  formik.errors.studentID ? 'form-control  error-input' : 'form-control'
                }`}
              />

              <div className="modal-actions">
                <Button
                  type="submit"
                  disabled={
                    existingUserStatus === 'loading' || !formik.isValid || formik.isSubmitting
                  }
                  text={existingUserStatus === 'loading' ? <Loader /> : 'Ajouter'}
                  className={
                    existingUserStatus === 'loading'
                      ? 'loading-add-user-btn'
                      : !formik.isValid || formik.isSubmitting
                      ? 'disabled-button blue'
                      : 'blue'
                  }
                />
                <Button
                  onClick={() => handleClose(id)}
                  type="button"
                  text="Fermer"
                  className="white"
                />
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default AddExistingUserForm;
