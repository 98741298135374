import React, { useState } from 'react';
import Button from '../../Button';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import FormControl from '../../Form/FormControl';
import { useSelector, useDispatch } from 'react-redux';
import './_addNonExisitingUserForm.scss';
import { addNonExistingUser } from '../../../slices/subscription';
import getSelectOptions from '../../../utilities/getSelectOptions';
import Loader from '../../Loader';

const AddNonExistingUserForm = ({ id, open, handleClose, data, ...rest }) => {
  const dispatch = useDispatch();
  let [clicked, setClicked] = useState(false);

  const { subscription, nonExistingUserStatus } = useSelector((state) => state.subscription);

  const initialValues = {
    name: '',
    lastName: '',
    phone: '',
    division: '',
  };

  const validationSchema = Yup.object({
    name: Yup.string().required('Veuillez renseigner le nom'),
    lastName: Yup.string().required('Veuillez renseigner le prénom'),
    phone: Yup.string()
      .trim()
      .matches(
        '(74|71|78|70|72|9|4|2|5|73|75|76|77|79)[0-9]{6}',
        'format incorrect du numéro de téléphone'
      )
      .max(8)
      .required('Veuillez renseigner le numéro de téléphone'),

    division: Yup.string().required('Veuillez renseigner le classe'),
  });
  const onSubmit = async (values, submitProps) => {
    try {
      data?.setLoading(true);
      if (subscription?.total_seats > 0) {
        if (clicked === false) {
          dispatch(
            addNonExistingUser({
              subscriptionID: subscription.id,
              name: values.name,
              lastName: values.lastName,
              phone: values.phone,
              division: values.division,
            })
          ).then((res) => {
            if (res.error) {
              submitProps.setErrors({ phone: res.error.message });
              submitProps.setStatus({ success: false });
              submitProps.setSubmitting(false);
            } else {
              setClicked(true);
              handleClose(id);
              submitProps.setStatus({ success: true });
              submitProps.setSubmitting(false);

              data?.setSnackbar({
                open: true,
                text: res.payload,
                state: 'success',
                autoHide: true,
              });
            }
          });
        }
      } else {
        handleClose(id);

        data?.setSnackbar({
          open: true,
          text: 'Vous avez utilisé la totalité de vos abonnements!',
          state: 'error',
          autoHide: true,
        });
      }
    } catch (err) {
      if (!err.message) {
        submitProps.setErrors({
          submit: "Oups, quelque chose s'est mal passé, veuillez réessayer!",
        });
      } else {
        submitProps.setErrors({ phone: err.message });
      }

      submitProps.setStatus({ success: false });
      submitProps.setSubmitting(false);
    }
  };

  return (
    <div className="add-new-user-form">
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        {(formik) => {
          return (
            <Form>
              <div className="user-name">
                <FormControl
                  control="input"
                  type="string"
                  name="lastName"
                  placeholder="Nom"
                  className={`${
                    formik.errors.lastName && formik.touched.lastName
                      ? 'form-control  error-input'
                      : 'form-control'
                  }`}
                />
                <FormControl
                  control="input"
                  type="string"
                  name="name"
                  placeholder="Prénom"
                  className={`${
                    formik.errors.name && formik.touched.name
                      ? 'form-control  error-input'
                      : 'form-control'
                  }`}
                />
              </div>

              <FormControl
                control="input"
                type="number"
                name="phone"
                placeholder="Téléphone"
                className={`${
                  formik.errors.phone && formik.touched.phone
                    ? 'form-control  error-input'
                    : 'form-control'
                }`}
              />

              <FormControl
                control="select"
                onChange={(value) => formik.setFieldValue('division', value.value)}
                value={formik.values.division}
                options={getSelectOptions(data?.options)}
                error={
                  formik.errors.division && formik.touched.division ? formik.errors.division : null
                }
                className={`${
                  formik.errors.division && formik.touched.division
                    ? 'select  error-select'
                    : 'select'
                }`}
              />
              <div className="modal-actions">
                <Button
                  type="submit"
                  disabled={
                    nonExistingUserStatus === 'loading' || !formik.isValid || formik.isSubmitting
                  }
                  text={nonExistingUserStatus === 'loading' ? <Loader /> : 'Ajouter'}
                  className={
                    nonExistingUserStatus === 'loading'
                      ? 'loading-add-user-btn'
                      : !formik.isValid || formik.isSubmitting
                      ? 'disabled-button add-existing-user-btn'
                      : 'add-existing-user-btn'
                  }
                />

                <Button onClick={() => handleClose(id)} text="Fermer" />
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default AddNonExistingUserForm;
