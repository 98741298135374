import React from 'react';
import { Field, ErrorMessage } from 'formik';
import TextError from './TextError';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';

import './_index.scss';

function Input({ name, errorMessage, className, ...rest }) {
  return (
    <>
      <div className="form-control">
        <div>
          <div className={`${className} field`}>
            <Field id={name} name={name} {...rest} />
          </div>
          {errorMessage ? (
            <p className="error">
              <ReportProblemIcon /> {errorMessage}
            </p>
          ) : (
            <ErrorMessage component={TextError} name={name} />
          )}
        </div>
      </div>
    </>
  );
}

export default Input;
