const SortBy = (table, sortby) => {
  let sortedTable = [];
  for (let i = 0; i < table.length; i++) {
    const cell = table?.find((el) => el[0] === sortby[i]);
    sortedTable.push(cell);
  }
  return sortedTable;
};

export default SortBy;
