import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import CardWrapper from '../../CardWrapper/CardWrapper';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '../../Button';
import './_index.scss';
import AddExistingUserForm from './AddExistingUserForm';
import AddNonExistingUserForm from './AddNonExistingUserForm';

const AddUserModal = ({ id, open, handleClose, data, ...rest }) => {
  const [isRegistered, setIsRegistered] = useState(true);
  return (
    <Dialog
      open={open}
      onClose={(e, reason) => {
        handleClose(id);
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="ta-modal add-user"
    >
      <CardWrapper
        title="Ajouter un utilisateur"
        className="add-user-modal-title"
        closeBtn
        onClick={() => handleClose(id)}
      >
        <DialogContent className="ta-modal-content-scroll">
          <div className="is-registred">L'utilisateur est-il inscrit sur le site ?</div>

          <ButtonGroup disableElevation variant="contained" className="is-registred-actions">
            <Button
              className={isRegistered ? 'blue' : 'white'}
              text="oui"
              onClick={() => setIsRegistered(true)}
            />
            <Button
              className={!isRegistered ? 'blue' : 'white'}
              text="non"
              onClick={() => setIsRegistered(false)}
            />
          </ButtonGroup>

          {isRegistered && (
            <AddExistingUserForm id={id} open={open} handleClose={handleClose} data={data} />
          )}
          {!isRegistered && (
            <AddNonExistingUserForm id={id} open={open} handleClose={handleClose} data={data} />
          )}
        </DialogContent>
      </CardWrapper>
    </Dialog>
  );
};

export default AddUserModal;
