import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { closeModal } from '../../slices/modals';
import useAuth from '../../hooks/useAuth';
import AddUserModal from '../Modals/AddUserModal';
import ProgressionModal from '../Modals/ProgressionModal';
import AddPointsModal from '../Modals/AddPointsModal';
import AddOfferModal from '../Modals/AddOfferModal';
import ImportFileHistoryModal from '../Modals/ImportFileHistory';

const ModalsProvider = (props) => {
  const { isAuthenticated } = useAuth();
  const { modals } = useSelector((state) => state.modals);
  const dispatch = useDispatch();
  const modalState = (id, key) => {
    const res = modals.find((modal) => modal.id === id);
    return res[key];
  };
  const handleClose = (id) => {
    dispatch(closeModal(id));
  };
  return isAuthenticated ? (
    <>
      <AddUserModal
        id="modal-add-user"
        open={modalState('modal-add-user', 'open')}
        data={modalState('modal-add-user', 'data')}
        handleClose={handleClose}
      />
      <ProgressionModal
        id="modal-progression"
        open={modalState('modal-progression', 'open')}
        data={modalState('modal-progression', 'data')}
        handleClose={handleClose}
      />
      <AddPointsModal
        id="modal-add-points"
        open={modalState('modal-add-points', 'open')}
        data={modalState('modal-add-points', 'data')}
        handleClose={handleClose}
      />
      <AddOfferModal
        id="modal-add-offer"
        open={modalState('modal-add-offer', 'open')}
        data={modalState('modal-add-offer', 'data')}
        handleClose={handleClose}
      />
      <ImportFileHistoryModal
        id="modal-import-file-history"
        open={modalState('modal-import-file-history', 'open')}
        data={modalState('modal-import-file-history', 'data')}
        handleClose={handleClose}
      />
    </>
  ) : null;
};

export default ModalsProvider;
