import { formatISO9075 } from 'date-fns';
import addZero from './addZero';

const changeDateFormat = (dateToUpdate, withTime) => {
  const date = new Date(dateToUpdate);
  const day = addZero(date.getDate());
  const month = addZero(date.getMonth() + 1);
  const year = date.getFullYear();
  const time = withTime ? formatISO9075(date, { representation: 'time' }).slice(0, 5) : '';

  return withTime ? day + '/' + month + '/' + year + ' ' + time : day + '/' + month + '/' + year;
};

export default changeDateFormat;
