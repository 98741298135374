import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import CardWrapper from '../../CardWrapper/CardWrapper';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import FormControl from '../../Form/FormControl';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../Button';
import constants from '../../../utilities/constants';
import Offer from './Offer';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import './_index.scss';
import { fetchUser } from '../../../slices/user';

const offers = constants.OFFERS;

const AddOfferModal = ({ id, open, handleClose, data, ...rest }) => {
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.user);

  const [subscriptionsNumber, setSubscriptionsNumber] = useState();
  const [error, setError] = useState();
  let addedOffer = {};

  useEffect(() => {}, []);

  const initialValues = {
    subscriptionsNumber: 1,
  };
  const validationSchema = Yup.object({});
  const handleCloseModal = () => {
    handleClose(id);
    setError('');
  };

  const onSubmit = async (values, submitProps) => {
    try {
    } catch (err) {
      if (!err.message) {
      } else {
        submitProps.setErrors({ subscriptionsNumber: err.message });
      }
      submitProps.setStatus({ success: false });
      submitProps.setSubmitting(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={(e, reason) => {
        handleClose(id);
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="ta-modal add-offer-modal"
    >
      <CardWrapper
        title="Ajouter des abonnements"
        className="add-offer-modal-title"
        closeBtn
        onClick={() => handleClose(id)}
      >
        <DialogContent className="ta-modal-content-scroll add-offer-modal-content">
          <div className="offers-list">
            <div className="offers">
              {/* {offers.map((el, key) => (
                <Offer
                  key={key}
                  title={el.title}
                  icon={el.icon}
                  onClick={() => setNewOfferId(el.id)}
                  className={el.id === newOfferId ? 'selected-offer offer-item' : 'offer-item'}
                />
              ))} */}
            </div>
            <div className="offer-details">
              {/* <img src={newOffer.description} alt="offer-description" /> */}
              <div className="offer-price">
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={onSubmit}
                >
                  {(formik) => {
                    setSubscriptionsNumber(formik.values.subscriptionsNumber);
                    return (
                      <Form>
                        <div className="offer-form">
                          <FormControl
                            control="input"
                            type="number"
                            name="subscriptionsNumber"
                            className={`${
                              formik.errors.subscriptionsNumber
                                ? 'form-control  error-input'
                                : 'form-control'
                            }`}
                          />
                          <div className="offer-total-price">
                            {/* {subscriptionsNumber * newOffer.unitPrice}dt */}
                          </div>
                        </div>
                        {error && (
                          <p className="error">
                            <ReportProblemIcon /> {error}
                          </p>
                        )}
                        <Button
                          type="submit"
                          disabled={!formik.isValid || formik.isSubmitting}
                          text="Ajouter"
                          className={
                            !formik.isValid || formik.isSubmitting ? 'disabled-button blue' : 'blue'
                          }
                        />
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>

          <Button onClick={handleCloseModal} text="Fermer" className="white" />
        </DialogContent>
      </CardWrapper>
    </Dialog>
  );
};

export default AddOfferModal;
