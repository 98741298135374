import React from 'react';
import { exportSubscriptionList, importSubscriptionList } from '../../slices/subscription';
import { useDispatch } from 'react-redux';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import csvFile from '../../assets/files/exemple-liste-utilisateur.xlsx';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';

const CSVFormat = ({ subscriptionID, setSnackbar, loading, setLoading }) => {
  const dispatch = useDispatch();
  const handleFileSelect = (event) => {
    dispatch(
      importSubscriptionList({
        subscriptionID: subscriptionID,
        fileData: { file: event.target.files[0] },
      })
    ).then((res) => {
      res.error
        ? setSnackbar({
            open: true,
            text: res?.error?.message,
            state: 'error',
            autoHide: true,
          })
        : res?.payload?.message
        ? res?.payload?.message.includes('erreur')
          ? setSnackbar({
              open: true,
              text: res?.payload?.message,
              state: 'warning',
              autoHide: false,
            })
          : setSnackbar({
              open: true,
              text: res?.payload?.message,
              state: 'success',
              autoHide: true,
            })
        : '';
    });
  };

  const exportFileHandler = (event) => {
    dispatch(exportSubscriptionList(subscriptionID)).then((res) => {
      res.error
        ? setSnackbar({
            open: true,
            text: res.error.message,
            state: 'error',
            autoHide: false,
          })
        : setSnackbar({
            open: true,
            text: res.payload.message,
            state: 'success',
            autoHide: false,
          });
    });
  };

  const uploadFile = () => {
    setLoading(true);
    document.querySelector('#file').click();
  };

  return (
    <div className="csv-format-component">
      <div className="export-table" title="Exporter sous format csv">
        <FileUploadIcon onClick={exportFileHandler} id="Export-users-list" />
      </div>
      <div className="import-table" title="Importer sous format csv">
        <input
          name="file"
          type="file"
          onChange={handleFileSelect}
          id="file"
          hidden
          accept=".csv,.ods, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        />
        <FileDownloadIcon onClick={uploadFile} />
      </div>

      <a
        className="help-icon"
        title="Télécharger un exemple de fichier excel"
        href={csvFile}
        download="exemple-liste-utilisateur.xlsx"
        id="download-example-file"
      >
        <QuestionMarkIcon />
      </a>
    </div>
  );
};

export default CSVFormat;
