import Im_Accueil from '../assets/img/icons/home.svg';
import Im_Facebook from '../assets/img/icons/facebook.svg';
import Im_Instagram from '../assets/img/icons/instagram.svg';
import Im_Youtube from '../assets/img/icons/youtube.svg';
import paymentByBankIcon from '../assets/img/icons/money-bag.svg';
import D17Icon from '../assets/img/icons/d17.png';
import onlinePaymentIcon from '../assets/img/icons/credit-card.svg';

export default {
  HEADERS_API: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  },

  DRAWER_WIDTH: 280,

  SIDEBAR_LIST: [
    {
      id: 1,
      icon: Im_Accueil,
      name: 'Accueil',
      url: '/',
      type: 'route',
      permission: false,
    },
  ],
  SOCIALS_LIST: [
    {
      name: 'Facebook',
      img: Im_Facebook,
      url: 'https://www.facebook.com/takiacademy',
    },
    {
      name: 'Instagram',
      img: Im_Instagram,
      url: 'https://www.instagram.com/takiacademy',
    },
    {
      name: 'Youtube',
      img: Im_Youtube,
      url: 'https://www.youtube.com/takiacademy',
    },
  ],

  BANKS: [
    {
      id: 1010101,
      name: 'Banque zitouna',
      account: 'Taki Academy',
      rib: '25 006 0000000317041 86',
    },
    {
      id: 1010102,
      name: 'Banque biat',
      account: 'Taki Academy',
      rib: '08 139 0310110000790 88',
    },
    {
      id: 1010103,
      name: 'La post tunisienne',
      account: 'Taki Academy',
      rib: '5359 4017 2354 0098',
    },
  ],

  SubscribersHeaderTable: [
    { id: 0, thead: 'ID' },
    { id: 1, thead: 'Utilisateur' },
    { id: 2, thead: 'Téléphone' },
    { id: 3, thead: 'Classe' },
    { id: 4, thead: 'Actions' },
  ],
  SubscribersAllowedList: ['id', 'name', 'last_name', 'phone', 'division'],
  SubscribersSortBy: ['id', 'username', 'phone', 'division'],

  PointHistoryHeaderTable: [
    { id: 1, thead: 'Code' },
    { id: 2, thead: 'Mode de paiement' },
    { id: 3, thead: 'Montant en dinars' },
    { id: 4, thead: 'Description' },
    { id: 5, thead: 'Date' },
    { id: 6, thead: 'Statut' },
  ],
  PointHistoryAllowedList: ['code', 'method', 'amount', 'description', 'date'],
  PointHistorySortBy: ['code', 'method', 'amount', 'description', 'date'],

  ImportFileHeaderTable: [
    { id: 1, thead: 'Utilisateur' },
    { id: 2, thead: 'Téléphone' },
    { id: 3, thead: 'Email' },
    { id: 4, thead: 'Classe' },
    { id: 5, thead: 'Statut' },
  ],
  ImportFileHistoryAllowedList: ['id', 'name', 'last_name', 'phone', 'division', 'email'],
  ImportFileHistorySortBy: ['user', 'phone', 'email', 'division'],
  PAYMENT_METHOD: [
    {
      id: 12345671,
      icon: paymentByBankIcon,
      name: 'Versement bancuaire',
      className: 'bank-payment',
    },
    { id: 12345671, icon: D17Icon, name: 'D17', className: 'd17-payment' },
    {
      id: 12345671,
      icon: onlinePaymentIcon,
      name: 'Paiement en ligne',
      className: 'online-payment',
    },
  ],
};
