import React from 'react';
import './_select.scss';
import Select from 'react-select';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';

const CostumSelect = ({ onChange, options, value, className, error }) => {
  const colourStyles = {
    menuList: (base) => ({
      ...base,
      maxHeight: '200px',
    }),
    control: (styles) => ({ ...styles, backgroundColor: 'white' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: 'white',
        color: 'black',
        backgroundColor: isFocused ? '#2ba7df' : 'default',
        color: isFocused ? 'white' : 'default',
        ':active': {
          ...styles[':active'],
          backgroundColor: '#2ba7df',
          color: 'white',
        },
      };
    },
  };

  const defaultValue = (options, value) => {
    return options ? options.find((option) => option.value === value) : '';
  };

  return (
    <div className={className}>
      <Select
        menuPlacement="top"
        placeholder={'Classe'}
        value={defaultValue(options, value)}
        onChange={(value) => {
          onChange(value);
        }}
        options={options}
        styles={colourStyles}
      />
      {error ? (
        <p className="error">
          <ReportProblemIcon /> {error}
        </p>
      ) : null}
    </div>
  );
};
export default CostumSelect;
